.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.accordion-button:not(.collapsed) {
  background-color: #fff !important;
}

.button-main button{
  border: 1px solid blue;
    border-radius: 5px;
    padding: 5px;
    background-color: #fff;
    color: #000;
    font-weight: 500;
}

.css-zmli8s-MuiButtonBase-root-MuiButton-root {
    background-color: #000075 !important;
    color: #000075 !important;
    border-color: #000075 !important;
    background-color: var(--variant-outlinedBg);
    color: #fff !important;
}

.css-nt424e-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type{
  border: none;
}

.css-nt424e-MuiPaper-root-MuiAccordion-root{
  border: none;
}

.css-1eztefp-MuiGrid-root{
  height: 100vh !important;
}

.css-1eztefp-MuiGrid-root{
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 83.333333%;
    -ms-flex-preferred-size: 83.333333%;
    flex-basis: 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 83.333333%;
    max-height: 100vh !important;
    overflow: auto;
    padding: 10px;
}

.css-17s1f3n-MuiGrid-root{
  height: 100vh;
}
  

.login-box{
  max-width: 600px !important;
  display: flex  !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
}

.main-head{
  font-size: 38px !important;
  color: #000075;
 

}

.box-bord{
  box-shadow: none !important;
  border: 1px solid #eee;

}

.login-btn{
  background-color: #000075 !important;
}

.css-up1j87-MuiGrid-root{
  max-height: 100vh;
  overflow: scroll;
}
.css-17s1f3n-MuiGrid-root{
  padding: 30px 0px 0px 30px !important;
}
.css-emtnwc-MuiGrid-root{
  overflow: auto !important;
}
